import * as React from 'react';
import { useRecoilState } from 'recoil';
import MultiSelectFilter from '../Filter/MultiSelectFilter';
import useFilter from '../hooks/useFilter';
import SortButton from './SortButton';
import { tableFilterAtomFamily } from '../CmdTableState';

interface ThMultiSelectProps {
  id: string;
  tableName: string;
  title: string;
  isSortable?: boolean;
  tooltip?: string;
}

function ThMultiSelect(props: ThMultiSelectProps): JSX.Element {
  const [tableActionsFilters, setTableActionsFilters] = useRecoilState(
    tableFilterAtomFamily({ [props.tableName]: props.id })
  );
  const { getFilterData } = useFilter(props.tableName);

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    const { id } = event.currentTarget;

    setTableActionsFilters(
      (tableActionFilter) =>
        tableActionFilter && {
          ...tableActionFilter,
          id: props.id,
          isOpen: true,
          isActionChipsOpen: false,
        }
    );

    if (id) {
      if (!tableActionsFilters) {
        getFilterData(props.tableName, props.id);
      }
    }
  };

  const renderMultiSelect = (): JSX.Element | null => {
    if (tableActionsFilters && tableActionsFilters.isOpen && tableActionsFilters.data) {
      return (
        <MultiSelectFilter
          id={props.id}
          tableName={props.tableName}
          filterValues={tableActionsFilters.data}
          title={props.title}
        />
      );
    }

    return null;
  };

  return (
    <div
      className={`${tableActionsFilters && tableActionsFilters.values ? 'active' : ''}`}
      style={{
        // minWidth: props.minWidth || '150px',
        // maxWidth: props.maxWidth || '300px',
        // flex: 1,
        // whiteSpace: 'nowrap',
        width: '100%',
        cursor: 'pointer',
      }}
      title={props.tooltip}
    >
      <div className="d-flex w-100">
        <div
          id={props.id}
          className="text-truncate cmd-table-header-text"
          onClick={handleOnClick}
          data-cy={`filter-button-text-${props.id}`}
        >
          {props.title}
        </div>
        <div className="cmd-table-header-actions">
          <button
            id={props.id}
            type="button"
            className="filter cmd-icons cmd-icon-filter"
            onClick={handleOnClick}
            data-cy={`filter-button-${props.id}`}
          >
            <span className="sr-only">Filter</span>
          </button>
          {props.isSortable && <SortButton id={props.id} tableName={props.tableName} />}
        </div>
        {renderMultiSelect()}
      </div>
    </div>
  );
}

export default ThMultiSelect;
