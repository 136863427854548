import { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import config from '../../config';
import { getRefreshToken } from '../components/UserData/RefreshTokenService';
import {
  setAuthToken,
  setAuthTokenExpiresAt,
  setRefreshToken,
  setRefreshTokenExpiresAt,
  setSocketServerToken,
} from './LoginService';
import AxiosInstance from './AxiosInstance';
import { logOut } from './UserService';

export interface HttpServiceOptions {
  showToaster?: boolean; // Show toaster for HTTP status 20x
  showErrorToaster?: boolean | number[]; // Show toaster for HTTP status > 30x or specific status code
  returnAxiosError?: boolean; // Returns the whole Axios error response istead of undefined when error occurs
  returnAxiosErrorResponseData?: boolean; // Returns only response data from Axios Error when error occurs
  requestTimeout?: number;
  baseUrl?: string;
  headers?: AxiosRequestHeaders;
  noAuthHeader?: boolean; // Do not send Authorisation header with JWT in request
  returnAxiosResponse?: boolean; // Returns full success Axios response with headers not only data
  ignoreRefreshTokenMiddleware?: boolean; // Ignore refresh token middleware
  queryParams?: Record<string, string>;
  data?: any;
  signal?: AbortSignal;
}

export const STATUS_ERROR = 'error';

export const STATUS_NOT_FOUND = 404;
export const STATUS_APP_ERROR = 500;

export const RESPONSE_STATUS_ERROR = 'error';
export const RESPONSE_STATUS_OK = 'ok';

export const AXIOS_BASE_URL = `${config.Backend.url}/api`;
export const AXIOS_TIMEOUT = 90000;
export const AXIOS_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  'Content-Type': 'application/json',
  'Access-Control-Expose-Headers': 'Content-Disposition',
};

export const RESPONSE_ERROR = 'error';
export const RESPONSE_OK = 'ok';

const createQueryParamsFromObject = (object: Record<string, any>): string => {
  const params = new URLSearchParams();
  Object.keys(object).forEach((queryItem: string) => {
    if (object[queryItem] && !Array.isArray(object[queryItem])) {
      params.append(queryItem, object[queryItem]);
    }
    if (object[queryItem] && Array.isArray(object[queryItem])) {
      // console.log('setting: ', queryItem, object[queryItem]);
      object[queryItem].forEach((value: string) => {
        params.append(`${queryItem}[]`, value);
      });
    }
  });
  // console.log('params: ', params.toString());
  return params.toString();
};

export const fetchNewToken = async () => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    return null;
  }
  try {
    const requestConfig: AxiosRequestConfig = {
      baseURL: AXIOS_BASE_URL,
      timeout: AXIOS_TIMEOUT,
    };

    return await AxiosInstance.post('/v2/auth/refresh-token', { refreshToken }, requestConfig)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error) => {
        return false;
      });

    // console.log('refreshTokenData: ', refreshTokenData);
    // return await post<
    //   {
    //     refresh_token: string;
    //   },
    //   SignInResponse
    // >(
    //   '/v2/auth/refresh-token',
    //   { refresh_token: refreshToken },
    //   {
    //     returnAxiosError: false,
    //     returnAxiosResponse: false,
    //     showToaster: false,
    //     ignoreRefreshTokenMiddleware: true,
    //     showErrorToaster: false,
    //     noAuthHeader: true,
    //   }
    // );
  } catch (error) {
    // console.log('error:' , error)
    return null;
  }
};

export const refreshAuth = async (failedRequest: any) => {
  // console.log('refreshAuth function');
  const newTokenData = await fetchNewToken();
  // console.log('newTokenData:', newTokenData);

  if (newTokenData) {
    failedRequest.response.config.headers.Authorization = `Bearer ${newTokenData.authToken}`;
    // console.log('failedRequest: ', failedRequest.response.config.headers.Authorization);
    // setHeaderToken(newToken);
    // you can set your token in storage too
    // setToken({ token: newToken });
    setAuthToken(newTokenData.authToken);
    setAuthTokenExpiresAt(newTokenData.authTokenExpiresAt);
    setRefreshToken(newTokenData.refreshToken);
    setRefreshTokenExpiresAt(newTokenData.refreshTokenExpiresAt);
    setSocketServerToken(newTokenData.socketServerToken);

    return Promise.resolve(newTokenData);
  }
  // you can redirect to login page here
  // router.push("/login");
  logOut();
  return Promise.reject();
};

export async function getRequest<T>(
  url: string,
  options?: HttpServiceOptions
): Promise<T | AxiosResponse<unknown, any> | false> {
  console.info('newGetRequest: ', url);
  let opts: HttpServiceOptions = { showToaster: true, showErrorToaster: true };
  if (options) {
    opts = { ...opts, ...options };
  }

  const requestOptions: AxiosRequestConfig = {
    baseURL: opts.baseUrl || AXIOS_BASE_URL,
    timeout: opts.requestTimeout || AXIOS_TIMEOUT,
    headers: opts.headers || AXIOS_HEADERS,
  };

  let urlWithParams = url;
  if (opts.queryParams) {
    urlWithParams = `${url}?${createQueryParamsFromObject(opts.queryParams)}`;
  }

  return AxiosInstance.get(urlWithParams, requestOptions)
    .then((response: AxiosResponse) => {
      if (opts.returnAxiosResponse) {
        return response;
      }
      const responseData: T = response.data;
      return responseData;
    })
    .catch((error) => {
      // if (
      //   Array.isArray(error.config?.showErrorToaster) &&
      //   error.config?.showErrorToaster.length > 0
      // ) {
      //   if (error.config?.showErrorToaster.includes(error.response.status)) {
      //     detailed500Error(error);
      //   }
      // }
      // if (error.config?.showErrorToaster === true) {
      //   detailed500Error(error);
      // }
      // Add interceptor for custom error handling
      if (opts.returnAxiosError) {
        return error.response;
      }
      if (opts.returnAxiosErrorResponseData) {
        return error.response.data;
      }
      return false;
    });
}

export async function postRequest<D, T>(
  url: string,
  data: D,
  options: HttpServiceOptions = { showToaster: true, showErrorToaster: true }
): Promise<T | false> {
  let opts: HttpServiceOptions = { showToaster: true, showErrorToaster: true };
  if (options) {
    opts = { ...opts, ...options };
  }
  console.info('newPostRequest: ', url, data, options, opts);

  // if (opts.showToaster && !interceptors.toasterInterceptor) {
  //   interceptors.toasterInterceptor = AxiosInstance.interceptors.response.use(
  //     handleSuccessResponseToast
  //   );
  // }
  // if (!opts.showToaster && interceptors.toasterInterceptor) {
  //   AxiosInstance.interceptors.response.eject(interceptors.toasterInterceptor);
  // }

  // if (!opts.noAuthHeader && !interceptors.authInterceptor) {
  //   interceptors.authInterceptor = AxiosInstance.interceptors.request.use(handleAuth);
  // }
  // if (opts.noAuthHeader && interceptors.authInterceptor) {
  //   AxiosInstance.interceptors.request.eject(interceptors.authInterceptor);
  // }

  // if (opts.showErrorToaster === true && !interceptors.errorToasterInterceptor) {
  //   interceptors.errorToasterInterceptor = AxiosInstance.interceptors.response.use(
  //     undefined,
  //     handleErrorResponseToast
  //   );
  // }
  // if (!opts.showErrorToaster && interceptors.errorToasterInterceptor) {
  //   AxiosInstance.interceptors.response.eject(interceptors.errorToasterInterceptor);
  // }

  let urlWithParams = url;
  if (opts.queryParams) {
    urlWithParams = `${url}?${createQueryParamsFromObject(opts.queryParams)}`;
  }

  return AxiosInstance.post(urlWithParams, data, opts)
    .then((response: AxiosResponse) => {
      if (opts.returnAxiosResponse) {
        return response;
      }
      const responseData: T = response.data;
      return responseData;
    })
    .catch((error) => {
      // if (Array.isArray(opts.showErrorToaster) && opts.showErrorToaster.length > 0) {
      //   if (opts.showErrorToaster.includes(error.response.status)) {
      //     handleErrorResponseToast(error);
      //   }
      // }
      // Add interceptor for custom error handling
      if (opts.returnAxiosError) {
        return error.response;
      }
      if (opts.returnAxiosErrorResponseData) {
        return error.response.data;
      }
      return false;
    });
}

export async function putRequest<D, T>(
  url: string,
  data: D,
  options: HttpServiceOptions = { showToaster: true, showErrorToaster: true }
): Promise<T | false> {
  let opts: HttpServiceOptions = { showToaster: true, showErrorToaster: true };
  if (options) {
    opts = { ...opts, ...options };
  }
  console.info('newPutRequest: ', url, data, options, opts);

  let urlWithParams = url;
  if (opts.queryParams) {
    urlWithParams = `${url}?${createQueryParamsFromObject(opts.queryParams)}`;
  }

  return AxiosInstance.put(urlWithParams, data, opts)
    .then((response: AxiosResponse) => {
      if (opts.returnAxiosResponse) {
        return response;
      }
      const responseData: T = response.data;
      return responseData;
    })
    .catch((error) => {
      // if (Array.isArray(opts.showErrorToaster) && opts.showErrorToaster.length > 0) {
      //   if (opts.showErrorToaster.includes(error.response.status)) {
      //     handleErrorResponseToast(error);
      //   }
      // }
      // Add interceptor for custom error handling
      if (opts.returnAxiosError) {
        return error.response;
      }
      if (opts.returnAxiosErrorResponseData) {
        return error.response.data;
      }
      return false;
    });
}

export async function patchRequest<D, T>(
  url: string,
  data: D,
  options: HttpServiceOptions = { showToaster: true, showErrorToaster: true }
): Promise<T | false> {
  let opts: HttpServiceOptions = { showToaster: true, showErrorToaster: true };
  if (options) {
    opts = { ...opts, ...options };
  }

  let urlWithParams = url;
  if (opts.queryParams) {
    urlWithParams = `${url}?${createQueryParamsFromObject(opts.queryParams)}`;
  }

  return AxiosInstance.patch(urlWithParams, data, opts)
    .then((response: AxiosResponse) => {
      if (opts.returnAxiosResponse) {
        return response;
      }
      const responseData: T = response.data;
      return responseData;
    })
    .catch((error) => {
      // if (Array.isArray(opts.showErrorToaster) && opts.showErrorToaster.length > 0) {
      //   if (opts.showErrorToaster.includes(error.response.status)) {
      //     handleErrorResponseToast(error);
      //   }
      // }
      // Add interceptor for custom error handling
      if (opts.returnAxiosError) {
        return error.response;
      }
      if (opts.returnAxiosErrorResponseData) {
        return error.response.data;
      }
      return false;
    });
}

export async function deleteRequest<T>(
  url: string,
  options: HttpServiceOptions = { showToaster: true, showErrorToaster: true }
): Promise<T | false> {
  let opts: HttpServiceOptions = { showToaster: true, showErrorToaster: true };
  if (options) {
    opts = { ...opts, ...options };
  }

  let urlWithParams = url;
  if (opts.queryParams) {
    urlWithParams = `${url}?${createQueryParamsFromObject(opts.queryParams)}`;
  }

  return AxiosInstance.delete(urlWithParams, opts)
    .then((response: AxiosResponse) => {
      if (opts.returnAxiosResponse) {
        return response;
      }
      const responseData: T = response.data;
      return responseData;
    })
    .catch((error) => {
      // Add interceptor for custom error handling
      if (opts.returnAxiosError) {
        return error.response;
      }
      if (opts.returnAxiosErrorResponseData) {
        return error.response.data;
      }
      return false;
    });
}
