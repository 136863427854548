import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CmdModal, CmdModalFooterButton } from '@commander-services/gui-components';
import { useLocation } from 'wouter';
import { useQueryClient } from '@tanstack/react-query';
import LoaderService from '../../../Services/LoaderService';
import { setDefaultCustomer } from '../../../Services/UserService';
import showMessage from '../../Toastr/ToastService';
import * as WidgetState from '../../../store/recoil/widgets';
import {
  IVehicleOnlinePanels,
  onlineMapTemplatesAtom,
  onlinePanelAtom,
} from '../../OnlinePanel/OnlinePanelState';
import { getMenuItems } from '../MainMenuService';
import { URL_MAPS } from '../../../router/constants';
import './customersStyles.scss';
import { IOnlinePanelData } from '../../OnlineMapNew/PanelsLayer/types';
import List from './List';
import { IMainMenuItem } from '../interfaces';
import {
  customersAtom,
  ICustomer,
  selectedCustomersAtom,
  selectedTempCustomersAtom,
} from '../../../store/recoil/customers';
import { createCustomersArray, getCustomers, setPermissions } from '../../UserData/UserDataService';
import {
  // IVehicleGroup,
  IVehicles,
  IVehiclesData,
  VehiclesBasicInfo,
  IVehicle,
} from '../../Vehicles/types';
import {
  selectedVehiclesAtom,
  // vehicleGroupsAtom,
  vehiclesAtom,
  vehiclesBasicInfoAtom,
  vehiclesOptionsAtom,
} from '../../../store/recoil/vehicles';
import { vehiclesDataAtom } from '../../../store/recoil/vehiclesData';
import {
  getSelectedVehiclesByCustomerId,
  getVehiclesCustomerId,
  unselectAllVehiclesByCustomerId,
  vehicleArrayToObject,
  vehicleDataArrayToObject,
  vehicleOnlinePanelArrayToObject,
} from '../../../Services/VehicleService';
import { IWidget } from '../../OnlinePanel/Widgets/types';
import {
  isBluecoinsOpenAtom,
  selectedBluecoinsAtom,
} from '../../OnlineMapNew/Bluecoins/BluecoinState';
import { isWaypointsOpenAtom } from '../../OnlineMapNew/Waypoints/WaypointState';
import { IOption } from '../../Forms/CmdField';
import {
  activeMenuIdAtom,
  activeMenuParentIdAtom,
  carRentalAtom,
  menuItemsAtom,
} from '../MainMenuState';
import {
  getVehicles,
  saveSelectedCustomers,
} from '../../Navigator/NavigatorService';
import { PermissionsProfilesResponse } from '../../UserData/types';
import useAnalytics from '../../../hooks/useAnalytics';

const SELECTED_CUSTOMERS_LIMIT = 50;

export default function SelectCustomers(): JSX.Element {
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const { formatMessage: f } = useIntl();
  const [pathname, navigate] = useLocation();

  const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

  const customers = useRecoilValue<ICustomer[]>(customersAtom);
  const [selectedCustomers, setSelectedCustomers] = useRecoilState<number[]>(selectedCustomersAtom);
  const setSelectedTempCustomer = useSetRecoilState<number[]>(selectedTempCustomersAtom);
  const selectedTempCustomers = useRecoilValue<number[]>(selectedTempCustomersAtom);
  const selectedVehicles = useRecoilValue<number[]>(selectedVehiclesAtom);
  const setSelectedVehicles = useSetRecoilState<number[]>(selectedVehiclesAtom);
  const vehicles = useRecoilValue<IVehicles>(vehiclesAtom);
  const setVehiclesData = useSetRecoilState<IVehiclesData>(vehiclesDataAtom);
  const setVehicles = useSetRecoilState<IVehicles>(vehiclesAtom);
  const setOnlinePanel = useSetRecoilState<IVehicleOnlinePanels>(onlinePanelAtom);
  const setMenuItems = useSetRecoilState<IMainMenuItem[]>(menuItemsAtom);
  const setCarRental = useSetRecoilState<string>(carRentalAtom);
  const setActiveMenuParentId = useSetRecoilState<number>(activeMenuParentIdAtom);
  const setMenuId = useSetRecoilState<number>(activeMenuIdAtom);
  const setCustomers = useSetRecoilState<ICustomer[]>(customersAtom);
  const setOnlineMapTemplates = useSetRecoilState<IOnlinePanelData[]>(onlineMapTemplatesAtom);
  const setUsedWidgets = useSetRecoilState<IWidget[]>(WidgetState.usedWidgets);
  const setIsBluecoinOpen = useSetRecoilState<boolean>(isBluecoinsOpenAtom);
  const setSelectedBluecoins = useSetRecoilState(selectedBluecoinsAtom);
  const setIsWayponitsOpen = useSetRecoilState<boolean>(isWaypointsOpenAtom);
  const setVehiclesOptions = useSetRecoilState<IOption[]>(vehiclesOptionsAtom);
  const setVehiclesBasicInfo = useSetRecoilState<VehiclesBasicInfo>(vehiclesBasicInfoAtom);

  const handleOpenModal = async () => {
    trackEvent(['Select customers', 'click', 'Open modal']);
    LoaderService.showLoader();
    const customersData = await getCustomers();
    if (customersData) {
      setPermissions(customersData as PermissionsProfilesResponse);
      const customersList: ICustomer[] = createCustomersArray(
        customersData as PermissionsProfilesResponse
      );

      if (customersList.length > 0) {
        setCustomers(customersList);
      }
    }
    LoaderService.showLoader(false);
    setSelectedTempCustomer(selectedCustomers);
    setIsModalOpened(true);
  };

  const handleCloseModal = () => {
    trackEvent(['Select customers', 'click', 'Close modal']);
    setIsModalOpened(false);
  };

  const handleCloseWpAndBle = () => {
    setIsBluecoinOpen(false);
    setIsWayponitsOpen(false);
    setSelectedBluecoins([]);
  };

  const handleSelectCustomers = async () => {
    trackEvent(['Select customers', 'click', 'Submit slected customers']);
    handleCloseWpAndBle();

    if (
      selectedTempCustomers.length === 0 ||
      customers.filter((i) => selectedTempCustomers.includes(i.id)).length === 0
    ) {
      showMessage('', 'selectCustomers.error', 'warning');
      return;
    }
    if (selectedTempCustomers.length > SELECTED_CUSTOMERS_LIMIT) {
      showMessage(
        '',
        f({ id: 'selectCustomers.maximumSelectedCustomers' }, { _count: SELECTED_CUSTOMERS_LIMIT }),
        'warning'
      );
      return;
    }

    const unselectedCustomers = selectedCustomers.filter(
      (item) => !selectedTempCustomers.includes(item)
    );

    if (unselectedCustomers.length > 0) {
      setSelectedVehicles((state: number[]) =>
        unselectAllVehiclesByCustomerId(state, vehicles, unselectedCustomers)
      );
    }

    if (selectedTempCustomers) {
      LoaderService.showLoader();
      const response = await saveSelectedCustomers(selectedTempCustomers);
      if (response) {
        const mainMenu = await getMenuItems();
        if (mainMenu && mainMenu.panel) {
          setMenuItems(mainMenu.panel.items);
          if (mainMenu.carRental && mainMenu.carRental.enabled) {
            setCarRental(mainMenu.carRental.url);
          }
        }

        setSelectedCustomers(selectedTempCustomers);

        // Invalidate user data query to refresh user data
        // (because it runs useEffect in UserData/index.tsx and sets selectedCustomers)
        queryClient.invalidateQueries({ queryKey: ['userData'] });
        queryClient.invalidateQueries({ queryKey: ['customers'] });
        queryClient.invalidateQueries({ queryKey: ['vehicles'] });
        queryClient.invalidateQueries({ queryKey: ['vehicleGroups'] });

        setDefaultCustomer(selectedTempCustomers);

        if (selectedTempCustomers.length > 0) {
          const newSelectedVehicles: number[] = [];
          selectedTempCustomers.forEach((customerId: number) => {
            const vehiclesData = getSelectedVehiclesByCustomerId(
              vehicles,
              selectedVehicles,
              customerId
            );
            Object.keys(vehiclesData).forEach((vehicleId: string) => {
              const vehicle = vehiclesData[Number(vehicleId)];
              if (vehicle) {
                newSelectedVehicles.push(vehicle.id);
              }
            });
          });

          if (selectedTempCustomers.length === 1) {
            const vehiclesByCustomer = getVehiclesCustomerId(
              vehicles,
              [],
              selectedTempCustomers[0]
            );
            if (vehiclesByCustomer.length === 1) {
              if (vehiclesByCustomer[0]) {
                setSelectedVehicles([vehiclesByCustomer[0].id]);
              }
            }
          }
          setSelectedVehicles(newSelectedVehicles);

          LoaderService.showNavigatorLoader();
          const responseVehicle = await getVehicles();
          if (responseVehicle) {
            const vehiclesOptions = Object.values(responseVehicle.vehicles).map((i) => {
              return { value: String(i.id), item: `${i.licenceNumber} - ${i.name}` };
            });
            const vehiclesBasicInfo: VehiclesBasicInfo = {};
            responseVehicle.vehicles.forEach((i: IVehicle) => {
              vehiclesBasicInfo[i.id] = {
                id: i.id,
                name: i.name,
                licenceNumber: i.licenceNumber,
                vehicleTypeId: i.vehicleTypeId,
                customerId: i.customerId,
              };
            });
            setVehicles(vehicleArrayToObject(responseVehicle.vehicles));
            setVehiclesData(vehicleDataArrayToObject(responseVehicle.vehiclesData));
            setOnlinePanel(vehicleOnlinePanelArrayToObject(responseVehicle.onlinePanelData));
            setUsedWidgets(responseVehicle.usedWidgets);
            setOnlineMapTemplates(responseVehicle.onlineMapTemplate);
            setVehiclesOptions(vehiclesOptions);
            setVehiclesBasicInfo(vehiclesBasicInfo);
            LoaderService.showNavigatorLoader(false);
            LoaderService.showLoader(false);
          }
        }

        const customersData = await getCustomers();
        if (customersData) {
          const customersList: ICustomer[] = createCustomersArray(
            customersData as PermissionsProfilesResponse
          );
          if (customersList.length > 0) {
            setCustomers(customersList);
          }
        }

        LoaderService.showLoader(false);

        setIsModalOpened(false);

        // If we are on maps page, redirect to fist available item in menu
        if (pathname === URL_MAPS && mainMenu) {
          if (!mainMenu.panel.items.find((item: IMainMenuItem) => item.link === URL_MAPS)) {
            if (mainMenu.panel.items[0].link) {
              setActiveMenuParentId(mainMenu.panel.items[0].id);
              navigate(mainMenu.panel.items[0].link);
            } else {
              setActiveMenuParentId(mainMenu.panel.items[0].id);
              setMenuId(mainMenu.panel.items[0].items[0].id);
              navigate(mainMenu.panel.items[0].items[0].link);
            }
          }
        }
      }
    }
  };

  const modalFooterButtons: CmdModalFooterButton[] = [
    {
      id: 'closeSelectCustomerModal',
      type: 'button',
      title: f({ id: 'form.close' }),
      className: 'e-button',
      closeCallback: handleCloseModal,
    },
    {
      id: 'submitSelectCustomerModal',
      type: 'submit',
      title: f({ id: 'navigator.selectCustomers.modal.submit' }),
      className: 'e-button e-button--gray',
      submitCallback: handleSelectCustomers,
    },
  ];

  return (
    <>
      <div className="sb-nav-sub-menu-wrapper">
        <div className="sb-nav-sub-menu-item">
          <a
            href="#"
            className="subMenu menuLink"
            onClick={handleOpenModal}
            data-cy="button-select-customers"
          >
            {' '}
            {f({ id: 'navigator.selectCustomers.button' })}{' '}
          </a>
        </div>
      </div>
      {isModalOpened && (
        <div style={{ textIndent: 0, cursor: 'default' }}>
          <CmdModal
            id="selectedCustomers"
            title={f({ id: 'navigator.selectCustomers.modal.title' })}
            footerButtons={modalFooterButtons}
            insideScroll={true}
          >
            <h5 className="fs-5 font-weight-normal mb-1 mt-0" style={{ lineHeight: 'normal' }}>
              <FormattedMessage id="navigator.selectCustomers.modal.content" />
            </h5>
            {selectedTempCustomers.length > SELECTED_CUSTOMERS_LIMIT && (
              <h5 className="fs-5 font-weight-normal mb-1 mt-0" style={{ lineHeight: 'normal' }}>
                {f(
                  { id: 'selectCustomers.maximumSelectedCustomers' },
                  { _count: SELECTED_CUSTOMERS_LIMIT }
                )}
              </h5>
            )}
            <div style={{ minHeight: '210px', cursor: 'pointer' }}>
              <List />
            </div>
          </CmdModal>
        </div>
      )}
    </>
  );
}
